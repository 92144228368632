import swal from 'sweetalert';

function deleteSweetAlertButton() {
  const deleteopt = document.querySelectorAll('a[id=delete-sweet]');
  deleteopt.forEach((swalButtonDelete) => {
  if (swalButtonDelete) { // protect other pages
     swalButtonDelete.addEventListener('click', (e) => {
      swal({
  title: "Are you sure?",
  text: "Item will be removed",
  icon: "warning",
  buttons: {
        cancel: "Cancel",
        delete: true
      },
  dangerMode: true,
  }).then((value) => {
    console.log(value)
        switch (value) {
          case "delete":
          const deleteButton = swalButtonDelete.parentElement.querySelector('a[id=delete-hidden]');
          deleteButton.click();
        }
      });
      });
    }
  });
}
function confirmAddSweetAlertButton() {
  const deleteopt = document.querySelectorAll('a[id=add-sweet]');
  deleteopt.forEach((swalButtonDelete) => {
  if (swalButtonDelete) { // protect other pages
     swalButtonDelete.addEventListener('click', (e) => {
      swal({
  title: "Are you sure?",
  text: "Amount will be added to that location",
  icon: "warning",
  buttons: {
        cancel: "Cancel",
        okay: true
      },
  dangerMode: true,
  }).then((value) => {
    console.log(value)
        switch (value) {
          case "okay":
          const deleteButton = swalButtonDelete.parentElement.querySelector('input[type=submit]');
          deleteButton.click();
        }
      });
      });
    }
  });
}
function partSweetAlertButtonSuccess() {
  const swalButtonMatch = document.getElementById('noticegratitude');
  if (swalButtonMatch) { // protect other pages
      swal({
      title: 'Part removed successfully',
      icon: "success",
      button: false,
      timer: 3500,
      closeOnClickOutside: false,
      width: 700,
      padding: 150,
      });
  }
}
function updateSweetAlertButtonSuccess() {
  const swalButtonMatch = document.getElementById('updated_for_payment');
  if (swalButtonMatch) { // protect other pages
      swal({
      title: 'Updated successfully',
      icon: "success",
      button: false,
      timer: 3500,
      closeOnClickOutside: false,
      width: 700,
      padding: 150,
      });
  }
}
function claimSweetAlertButtonSuccess() {
  const swalButtonMatch = document.getElementById('noticeclaim');
  if (swalButtonMatch) { // protect other pages
      swal("Good job!", "Claim has been saved", "success", {
  button: "Okay",
  }).then((value) =>{
        swal("But not finished yet review and then click on compelete");
      });
  }
}
function warningSweetAlertButtonSuccess() {
  const swalButtonMatch = document.getElementById('warningclaim');
  if (swalButtonMatch) { // protect other pages
      swal("Sorry!", "Status has been finalized", "warning", {
  button: "Okay",
  }).then((value) =>{
        swal("This claim cannot be updated, please check for error(s) or you may contact us for more info.");
      });
  }
}
function claimWarningSweetAlertButtonSuccess() {
  const swalButtonMatch = document.getElementById('uploadwarningclaim');
  if (swalButtonMatch) { // protect other pages
      swal("Hey!", "Upload has been submitted", "warning", {
  button: "Okay",
  }).then((value) =>{
        swal("The uploading is downloading data from the server once compelete an email will be sent out. If errors found please review and only upload error claims to avoid duplicating.");
      });
  }
}
function csvSweetAlertButtonSuccess() {
  const swalButtonMatch = document.getElementById('noticehappy');
  if (swalButtonMatch) { // protect other pages
      swal({
      title: 'Created successfully',
      icon: "success",
      button: false,
      timer: 3500,
      closeOnClickOutside: false,
      width: 700,
      padding: 150,
      });
  }
}
function partCannotFound(){
  const swalButtonMatch = document.getElementById('part_cannot_found');
  if (swalButtonMatch) { // protect other pages
  swal({
    icon: 'error',
    title: 'Oops...',
    text: 'We do not have record of this Ecompass invoice. Please contact us for us to review.'
  });
  }
}
function passwordWarningSweetAlertButtonSuccess() {
  const swalButtonMatch = document.getElementById('user_password_reset');
  if (swalButtonMatch) { // protect other pages
      swal("Hey!", "A password reset has been sent", "warning", {
  button: "Okay",
  }).then((value) =>{
        swal("The user will need to click the link and confirm a new password.");
      });
  }
}
function updateSweetAlertButton() {
  const deleteopt = document.querySelectorAll('a[id=password-sweet]');
  deleteopt.forEach((swalButtonDelete) => {
  if (swalButtonDelete) { // protect other pages
     swalButtonDelete.addEventListener('click', (e) => {
      swal({
  title: "Are you sure?",
  text: "The user will be sent a email to change account's password",
  icon: "warning",
  buttons: {
        cancel: "Cancel",
        okay: true
      },
  dangerMode: true,
  }).then((value) => {
    console.log(value)
        switch (value) {
          case "okay":
          const okayButton = swalButtonDelete.parentElement.querySelector('a[id=password-hidden]');
          okayButton.click();
        }
      });
      });
    }
  });
}
function buyerModelWarningSweetAlertButtonSuccess() {
  const swalButtonMatch = document.getElementById('buyer_model_added');
  if (swalButtonMatch) { // protect other pages
      swal("New buyermodel", "Saved", "warning", {
  button: "Okay",
  }).then((value) =>{
        swal("Next to add the model and serial number");
      });
  }
}
function dispatchOfferCannot(){
  const swalButtonMatch = document.getElementById('no_reoffer_dispatch');
  if (swalButtonMatch) { // protect other pages
  swal({
    icon: 'error',
    title: 'Oops...',
    text: 'The status is not open or asc did not decline.'
  });
  }
}
function dispatchReceiveError(){
  const swalButtonMatch = document.getElementById('not_complete_error');
  if (swalButtonMatch) { // protect other pages
  swal({
    icon: 'error',
    title: 'Oops...',
    text: 'Dispatch is not complete so pass ticket to Nancy.'
  });
  }
}
function dispatchReceiveDuplicateError(){
  const swalButtonMatch = document.getElementById('not_receive_error');
  if (swalButtonMatch) { // protect other pages
  swal({
    icon: 'error',
    title: 'Oops...',
    text: 'Dispatch shows a kit was received pass ticket to Nancy.'
  });
  }
}
function searchLookupReceiveErrorEnglish(){
  const swalButtonMatch = document.getElementById('not_complete_asc_error_en');
  if (swalButtonMatch) { // protect other pages
  swal({
    icon: 'error',
    title: 'Limit reached',
    text: 'You reached your lookup rate 10 per day.'
  });
  }
}
function searchLookupReceiveErrorSpanish(){
  const swalButtonMatch = document.getElementById('not_complete_asc_error_es');
  if (swalButtonMatch) { // protect other pages
  swal({
    icon: 'error',
    title: 'Límite alcanzado',
    text: 'Alcanzó su tasa de búsqueda 10 por día.'
  });
  }
}
function searchLookupReceiveErrorFrench(){
  const swalButtonMatch = document.getElementById('not_complete_asc_error_fr');
  if (swalButtonMatch) { // protect other pages
  swal({
    icon: 'error',
    title: 'Limite atteinte',
    text: 'Vous avez atteint votre taux de recherche 10 par jour.'
  });
  }
}
function warningNagamineSweetAlertButtonSuccess() {
  const swalButtonMatch = document.getElementById('nagaminewarningclaim');
  if (swalButtonMatch) { // protect other pages
      swal("Guys!", "We are looking for bad HDMI boards that were replaced", "warning", {
  button: "Okay",
  }).then((value) =>{
        swal("Please read the repair and if the board was replaced pass the Digital Main to Nagamine-san and write down the Dispatch Number.");
      });
  }
}
function codeDoNotMatch(){
  const swalButtonMatch = document.getElementById('problem_code');
  if (swalButtonMatch) { // protect other pages
  swal({
    icon: 'error',
    title: 'Oops...',
    text: 'There is a Symptom or Repair code that does not match in our system. Please review and adjust.'
  });
  }
}
function paymentStatmentButtonSuccess() {
  const swalButtonMatch = document.getElementById('paymentscript');
  if (swalButtonMatch) { // protect other pages
      swal("Good job!", "Almost ready for the card please read the following to advise the name of the company that will appear on their statement.", "success", {
  button: "Okay",
  }).then((value) =>{
        swal("Statment", "Before we are about to charge you, please be advise the service of goods is provided by Funai Service Corp. This name will appear on your statement with your verbal confirmation would you like to continue with your order with us today?");
      });
  }
}
export { claimWarningSweetAlertButtonSuccess, partCannotFound, csvSweetAlertButtonSuccess, deleteSweetAlertButton, partSweetAlertButtonSuccess, updateSweetAlertButtonSuccess, claimSweetAlertButtonSuccess, warningSweetAlertButtonSuccess, passwordWarningSweetAlertButtonSuccess,  updateSweetAlertButton, buyerModelWarningSweetAlertButtonSuccess, dispatchOfferCannot, dispatchReceiveError, dispatchReceiveDuplicateError, warningNagamineSweetAlertButtonSuccess, codeDoNotMatch, paymentStatmentButtonSuccess, confirmAddSweetAlertButton, searchLookupReceiveErrorEnglish, searchLookupReceiveErrorSpanish, searchLookupReceiveErrorFrench};

