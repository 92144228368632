import "core-js/stable";
import "regenerator-runtime/runtime";
import $ from 'jquery';
import "popper.js";
import "bootstrap";
import printJS from 'print-js';
import "../plugins/flatpickr";
import "../plugins/data_table";
import { initSelect2, initSelect2Tags } from "../plugins/init_select2";
import { claimWarningSweetAlertButtonSuccess, partCannotFound, csvSweetAlertButtonSuccess, deleteSweetAlertButton, updateSweetAlertButtonSuccess, partSweetAlertButtonSuccess, claimSweetAlertButtonSuccess, warningSweetAlertButtonSuccess, passwordWarningSweetAlertButtonSuccess,  updateSweetAlertButton, buyerModelWarningSweetAlertButtonSuccess, dispatchOfferCannot, dispatchReceiveError, dispatchReceiveDuplicateError, warningNagamineSweetAlertButtonSuccess, codeDoNotMatch, paymentStatmentButtonSuccess, confirmAddSweetAlertButton, searchLookupReceiveErrorEnglish, searchLookupReceiveErrorSpanish, searchLookupReceiveErrorFrench } from "../plugins/init_sweetalert";

warningNagamineSweetAlertButtonSuccess();
buyerModelWarningSweetAlertButtonSuccess();
passwordWarningSweetAlertButtonSuccess();
updateSweetAlertButton();
claimWarningSweetAlertButtonSuccess();
initSelect2();
initSelect2Tags();
partCannotFound();
csvSweetAlertButtonSuccess();
deleteSweetAlertButton();
partSweetAlertButtonSuccess();
updateSweetAlertButtonSuccess();
claimSweetAlertButtonSuccess();
warningSweetAlertButtonSuccess();
dispatchOfferCannot();
dispatchReceiveError();
dispatchReceiveDuplicateError();
codeDoNotMatch();
paymentStatmentButtonSuccess();
confirmAddSweetAlertButton();
searchLookupReceiveErrorEnglish();
searchLookupReceiveErrorSpanish();
searchLookupReceiveErrorFrench();

if(document.getElementById('claim_parts')) {
  const mileCheck = document.getElementById("claim_mileage");
  const additionalCheck = document.getElementById("claim_additional_request");
  const milePrice = document.querySelector(".claim_claim_miles");
  const additionalPrice = document.querySelector(".claim_additional_request_price_cents");
  const parts = document.querySelector(".parts-info");
  const additionalComment = document.querySelector(".claim_additional_comment");

  milePrice.style.display = "none";
  additionalPrice.style.display = "none";
  additionalComment.style.display = "none";

  function myMiles() {
    if(this.checked){
      milePrice.style.display = "initial";
    } else {
      document.getElementById("claim_claim_miles").value = 0;
      milePrice.style.display = "none";
    }
  }

  function myAdditional() {
    if(this.checked){
      additionalPrice.style.display = "initial";
      additionalComment.style.display = "initial";
    } else {
      document.getElementById("claim_additional_request_price_cents").value = 0;
      document.getElementById("claim_additional_comment").value = "";
      additionalPrice.style.display = "none";
      additionalComment.style.display = "none";
    }
  }

  mileCheck.addEventListener("change", myMiles);
  additionalCheck.addEventListener("change", myAdditional);

}

$(document).ready(function() {
  $('#asclookup').dataTable( {
    "language": {
      "emptyTable": "Not found, please try again.",
    },
    "order": [[ 3, "asc" ]],
  } );
});

$(document).ready(function() {
  $('#asclookup_es').dataTable( {
    "language": {
      "emptyTable": "No encontrado, inténtalo de nuevo.",
      url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Spanish.json'
    },
    "order": [[ 3, "asc" ]],
  } );
});

$(document).ready(function() {
  $('#asclookup_fr').dataTable( {
    "language": {
      "emptyTable": "Non trouvé, veuillez réessayer.",
      url: '//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json'
    },
    "order": [[ 3, "asc" ]],
  } );
});

$(document).ready(function() {
    $('#example').DataTable({
       "order": [[ 0, "asc" ]]
    });
} );


$(document).ready(function() {
    $('#example1').DataTable();
} );

$(document).ready(function() {
    $('#example2').DataTable();
} );

$(document).ready(function() {
    $('#example3').DataTable();
} );

$(document).ready(function() {
    $('#example4').DataTable();
} );

$(document).ready(function() {
    $('#example5').DataTable();
} );

$(document).ready(function() {
    $('#example6').DataTable();
} );

$(document).ready(function() {
    $('#example7').DataTable();
} );

$(document).ready(function() {
    $('#example8').DataTable();
} );

$(document).ready(function() {
    $('#example9').DataTable();
} );

$(document).ready(function() {
    $('#example10').DataTable();
} );

(function($) {

  'use strict';

  $(document).on('show.bs.tab', '.nav-tabs-responsive [data-toggle="tab"]', function(e) {
    var $target = $(e.target);
    var $tabs = $target.closest('.nav-tabs-responsive');
    var $current = $target.closest('li');
    var $parent = $current.closest('li.dropdown');
    $current = $parent.length > 0 ? $parent : $current;
    var $next = $current.next();
    var $prev = $current.prev();
    var updateDropdownMenu = function($el, position){
      $el
        .find('.dropdown-menu')
        .removeClass('pull-xs-left pull-xs-center pull-xs-right')
        .addClass( 'pull-xs-' + position );
    };

    $tabs.find('>li').removeClass('next prev');
    $prev.addClass('prev');
    $next.addClass('next');

    updateDropdownMenu( $prev, 'left' );
    updateDropdownMenu( $current, 'center' );
    updateDropdownMenu( $next, 'right' );
  });

})(jQuery);

$("#question-accounting").popover({
  title: "Accounting pending claims hint",
  content: "The total amount of claims pending to be batched to accounting for payment.",
  trigger: "hover"
});

$("#question-warranty").popover({
  title: "Warranty pending claims hint",
  content: "The total amount of claims pending to be reviewed.",
  trigger: "hover"
});

$("#question-shipping").popover({
  title: "Warranty pending kit shipping hint",
  content: "The total of kits pending to be shipped.",
  trigger: "hover"
});

$("#question-open-dispatches").popover({
  title: "Warranty open dispatches hint",
  content: "The total of open dispatches for in-home service.",
  trigger: "hover"
});

$("#question-open-kit").popover({
  title: "Warranty open dispatches hint",
  content: "The total of open dispatches claimed by ASC just waiting on kit to be received back.",
  trigger: "hover"
});

$("#question-open-parts").popover({
  title: "Warranty open dispatches hint",
  content: "The total of open dispatches ASC accepted just waiting on a kit to be ordered.",
  trigger: "hover"
});

$("#question-open-response").popover({
  title: "Warranty open dispatches hint",
  content: "The total of open dispatches ASC assigned just waiting to be accepted.",
  trigger: "hover"
});

$("#question-open-returned").popover({
  title: "Warranty open dispatches hint",
  content: "The total of open dispatches ASC returned kit but did not complete a claim.",
  trigger: "hover"
});

$("#question-open-past").popover({
  title: "Warranty open dispatches hint",
  content: "The total of open dispatches ASC accepted and gone past 14 days open.",
  trigger: "hover"
});

$("#question-open-dispatches-fedex").popover({
  title: "Warranty open dispatches hint",
  content: "The total of open dispatches FedEx shows kit return was delivered and ASC claimed.",
  trigger: "hover"
});

$("#question-servicers").popover({
  title: "Warranty servicers hint",
  content: "The total of active or suspended servicers.",
  trigger: "hover"
});

$("#question-pending-payment").popover({
  title: "Warranty servicers hint",
  content: "The total of servicers requesting additional funds.",
  trigger: "hover"
});
