import $ from "jquery";
import 'select2';
import "select2/dist/css/select2.css";

const initSelect2 = function() {
 if(document.getElementsByClassName('select2')){
   $('.select2').select2({ width: '100%' }); // (~ document.querySelectorAll)
 }
};

const initSelect2Tags = function() {
 if(document.getElementsByClassName('select2-tags')){
   $('.select2-tags').select2({ width: '100%', tags: true, multiple: true }); // (~ document.querySelectorAll)
 }
};

export { initSelect2, initSelect2Tags };
